import { useState, useCallback } from "react";

const useHttp = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async (data) => {
      setIsLoading(true);
      setError(null);
      //console.log(JSON.stringify(data));
      let result = [{ Name: "bad request" }];
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((res) => {
            setIsLoading(false);
            //console.log(res);
            result = res;
          });

        if (!response) {
          setError(null);
        }
      } catch (err) {
        console.log(err);
        setError((i) => true);
      }
      setIsLoading(false);
      return result;
    },
    [url]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
