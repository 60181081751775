import { useState, useEffect } from "react";
// import Button from "../../Components/Button/Button";
import useInput from "../../Hooks/useInput";
import usePost from "../../Hooks/usePost";
import Grid from "@mui/material/Grid";
import { Stack, TextField, Button, Typography } from "@mui/material";
import UnitDropdown from "../../Components/Dropdown";

const intialForm = {
  formName: "",
  formLength: 0,
  formBreadth: 0,
  formHeight: 0,
  formLength2d: 0,
  formBreadth2d: 0,
  formGSM1D: 0,
  formGSM2D: 0,
  formGSM3D: 0,
  formGSM4D: 0,
  formGSM5D: 0,
  formGSM1R: 0,
  formGSM2R: 0,
  formGSM3R: 0,
  formGSM4R: 0,
  formGSM5R: 0,
  formPly: 0,
  formConcost: 0,
  formColors: 0,
  formWastage: 0,
  formLogistic: 0,
  formPacking: 0,
  formProfit: 0,
};
// helper function to get the unit multiplier

const CorrugatedForm = (props) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState(false);
  const [unit, setUnit] = useState("mm");
  const {
    isLoading: postLoading,
    error: postError,
    sendRequest: postCorrugatedForm,
  } = usePost(`https://rrcosting.click/corrugated/calculate`, { unit });

  const {
    val: itemName,
    valChange: itemNameChange,
    valBlur: itemNameBlur,
    valReset: itemNameReset,
    valError: itemNameHasError,
    helperText: itemNameHelper,
    inputRef: itemNameRef,
  } = useInput(
    intialForm.formName, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ", //untouched
    "Name cannot be empty", //touched and wrong
    " "
  );

  const {
    val: L_box,
    valChange: L_boxChange,
    valBlur: L_boxBlur,
    valReset: L_boxReset,
    helperText: L_boxHelper,
    valError: L_boxError,
    inputRef: L_boxRef,
  } = useInput(
    intialForm.formLength, //intialValue
    (i) => (i > 0 ? true : false), //validation
    `(${unit})`,
    ">0",
    " "
  );

  const {
    val: B_box,
    valChange: B_boxChange,
    valBlur: B_boxBlur,
    valReset: B_boxReset,
    helperText: B_boxHelper,
    valError: B_boxError,
    inputRef: B_boxRef,
  } = useInput(
    intialForm.formBreadth, //intialValue
    (i) => (i > 0 ? true : false), //validation
    `(${unit})`,
    ">0",
    " "
  );

  const {
    val: H_box,
    valChange: H_boxChange,
    valBlur: H_boxBlur,
    valReset: H_boxReset,
    helperText: H_boxHelper,
    valError: H_boxError,
    inputRef: H_boxRef,
  } = useInput(
    intialForm.formHeight, //intialValue
    (i) => (i > 0 || i === null ? true : false), //validation
    `(${unit})`,
    ">0",
    " "
  );
  // Required only if rate is give. There is no requirement for all.
  const {
    val: dimension1,
    valChange: dimension1Change,
    valBlur: dimension1Blur,
    valReset: dimension1Reset,
    helperText: dimension1Helper,
    valError: dimension1Error,
    inputRef: dimension1Ref,
  } = useInput(
    intialForm.formGSM1D, //intialValue
    (i) => (i > 0 ? true : false), //validation
    "Required",
    ``,
    ""
  );

  const {
    val: dimension2,
    valChange: dimension2Change,
    valBlur: dimension2Blur,
    valReset: dimension2Reset,
    helperText: dimension2Helper,
    valError: dimension2Error,
    inputRef: dimension2Ref,
  } = useInput(
    intialForm.formGSM2D, //intialValue
    (i) => (dimension1 && i > 0 ? true : false), //validation
    "&D1",
    ``,
    " "
  );

  const {
    val: dimension3,
    valChange: dimension3Change,
    valBlur: dimension3Blur,
    valReset: dimension3Reset,
    helperText: dimension3Helper,
    valError: dimension3Error,
    inputRef: dimension3Ref,
  } = useInput(
    intialForm.formGSM3D, //intialValue
    (i) => (dimension2 && i > 0 ? true : false), //validation
    "&D2",
    ``,
    " "
  );

  const {
    val: dimension4,
    valChange: dimension4Change,
    valBlur: dimension4Blur,
    valReset: dimension4Reset,
    helperText: dimension4Helper,
    valError: dimension4Error,
    inputRef: dimension4Ref,
  } = useInput(
    intialForm.formGSM4D, //intialValue
    (i) => (dimension3 && i > 0 ? true : false), //validation
    "&D3",
    ``,
    " "
  );

  const {
    val: dimension5,
    valChange: dimension5Change,
    valBlur: dimension5Blur,
    valReset: dimension5Reset,
    helperText: dimension5Helper,
    valError: dimension5Error,
    inputRef: dimension5Ref,
  } = useInput(
    intialForm.formGSM5D, //intialValue
    (i) => (dimension5 && i > 0 ? true : false), //validation
    "&D4",
    ``,
    " "
  );

  const {
    val: rate1,
    valChange: rate1Change,
    valBlur: rate1Blur,
    valReset: rate1reset,
    helperText: rate1Helper,
    valError: rate1Error,
    inputRef: rate1Ref,
  } = useInput(
    intialForm.formGSM1R, //intialValue
    (i) => (dimension1 && i > 0 ? true : false), //validation
    "Required",
    "₹",
    " "
  );

  const {
    val: rate2,
    valChange: rate2Change,
    valBlur: rate2Blur,
    valReset: rate2Reset,
    helperText: rate2Helper,
    valError: rate2Error,
    inputRef: rate2Ref,
  } = useInput(
    intialForm.formGSM2R, //intialValue
    (i) => (dimension2 && i > 0 ? true : false), //validation
    " ",
    "₹",
    " "
  );

  const {
    val: rate3,
    valChange: rate3Change,
    valBlur: rate3Blur,
    valReset: rate3Reset,
    helperText: rate3Helper,
    valError: rate3Error,
    inputRef: rate3Ref,
  } = useInput(
    intialForm.formGSM3R, //intialValue
    (i) => (dimension3 && i > 0 ? true : false), //validation
    " ",
    "₹", //validation
    " "
  );

  const {
    val: rate4,
    valChange: rate4Change,
    valBlur: rate4Blur,
    valReset: rate4Reset,
    helperText: rate4Helper,
    valError: rate4Error,
    inputRef: rate4Ref,
  } = useInput(
    intialForm.formGSM4R, //intialValue
    (i) => (dimension4 && i > 0 ? true : false), //validation
    " ",
    "₹", //validation
    " "
  );

  const {
    val: rate5,
    valChange: rate5Change,
    valBlur: rate5Blur,
    valReset: rate5Reset,
    helperText: rate5Helper,
    valError: rate5Error,
    inputRef: rate5Ref,
  } = useInput(
    intialForm.formGSM5R, //intialValue
    (i) => (dimension5 && i > 0 ? true : false), //validation
    " ",
    "₹", //validation
    " "
  );

  const {
    val: Ply,
    valChange: PlyChange,
    valBlur: PlyBlur,
    valReset: PlyReset,
    helperText: PlyHelper,
    valError: PlyError,
    inputRef: PlyRef,
  } = useInput(
    intialForm.formPly, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be a number",
    " "
  );

  const {
    val: Conversion_Cost,
    valChange: Conversion_CostChange,
    valBlur: Conversion_CostBlur,
    valReset: Conversion_CostReset,
    helperText: Conversion_CostHelper,
    valError: Conversion_CostError,
    inputRef: Conversion_CostRef,
  } = useInput(
    intialForm.formConcost, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be a number",
    " "
  );

  const {
    val: Colors,
    valChange: ColorsChange,
    valBlur: ColorsBlur,
    valReset: ColorsReset,
    helperText: ColorsHelper,
    valError: ColorsError,
    inputRef: ColorsRef,
  } = useInput(
    intialForm.formColors, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be a number",
    " "
  );
  // same as l_box or B_box
  const {
    val: L_cutout,
    valChange: L_cutoutChange,
    valBlur: L_cutoutBlur,
    valReset: L_cutoutReset,
    helperText: L_cutoutHelper,
    valError: L_cutoutError,
    inputRef: L_cutoutRef,
  } = useInput(
    intialForm.formLength2d, //intialValue
    (i) => (i > 0 ? true : false), //validation
    `(${unit})`,
    ">0",
    " "
  );

  const {
    val: B_cutout,
    valChange: B_cutoutChange,
    valBlur: B_cutoutBlur,
    valReset: B_cutoutReset,
    helperText: B_cutoutHelper,
    valError: B_cutoutError,
    inputRef: B_cutoutRef,
  } = useInput(
    intialForm.formBreadth2d, //intialValue
    (i) => (i > 0 ? true : false), //validation
    `(${unit})`,
    ">0", //validation
    " "
  );

  const {
    val: Wastage,
    valChange: WastageChange,
    valBlur: WastageBlur,
    valReset: WastageReset,
    helperText: WastageHelper,
    valError: WastageError,
    inputRef: WastageRef,
  } = useInput(
    intialForm.formWastage, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be in Percentage", //validation
    " "
  );

  const {
    val: Logistics,
    valChange: LogisticsChange,
    valBlur: LogisticsBlur,
    valReset: LogisticsReset,
    helperText: LogisticsHepler,
    valError: LogisticsError,
    inputRef: LogisticsRef,
  } = useInput(
    intialForm.formLogistic, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be in Percentage",
    " "
  );

  const {
    val: Packaging_Cost,
    valChange: Packaging_CostChange,
    valBlur: Packaging_CostBlur,
    valReset: Packaging_CostReset,
    helperText: Packaging_CostHelper,
    valError: Packaging_CostError,
    inputRef: Packaging_CostRef,
  } = useInput(
    intialForm.formPacking, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be in Percentage",
    " "
  );

  const {
    val: Profit,
    valChange: ProfitChange,
    valBlur: ProfitBlur,
    valReset: ProfitReset,
    helperText: ProfitHelper,
    valError: ProfitError,
    inputRef: ProfitRef,
  } = useInput(
    intialForm.formProfit, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be in Percentage",
    " "
  );

  const resetForm = () => {
    L_boxReset();
    B_boxReset();
    H_boxReset();
    dimension1Reset();
    dimension2Reset();
    dimension3Reset();
    dimension4Reset();
    dimension5Reset();
    rate1reset();
    rate2Reset();
    rate3Reset();
    rate4Reset();
    rate5Reset();
    PlyReset();
    Conversion_CostReset();
    ColorsReset();
    L_cutoutReset();
    B_cutoutReset();
    WastageReset();
    LogisticsReset();
    Packaging_CostReset();
    ProfitReset();
    itemNameReset();
    setFormIsValid((i) => false);
    setError((i) => false);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (formIsValid) {
      const formData = {
        name: itemName,
        length: L_box,
        breadth: B_box,
        height: H_box,
        GSM1D: dimension1,
        GSM2D: dimension2,
        GSM3D: dimension3,
        GSM4D: dimension4,
        GSM5D: dimension5,
        GSM1R: rate1,
        GSM2R: rate2,
        GSM3R: rate3,
        GSM4R: rate4,
        GSM5R: rate5,
        Ply: Ply,
        Conversion_Cost: Conversion_Cost,
        Colors: Colors,
        length2d: L_cutout,
        breadth2d: B_cutout,
        Wastage: Wastage,
        Logistics: Logistics,
        Packaging_Cost: Packaging_Cost,
        Profit: Profit,
        user_id: props.user,
        unit: unit,
      };

      console.log(formData);
      postCorrugatedForm(formData)
        .then((res) => {
          console.log(res.data.result);
          props.updateData(res.data.result);
        })
        .then(resetForm());
    } else {
      setError((i) => true);
    }
  };

  const nextFieldSelect = (event, next) => {
    const { key } = event;
    if (key === "Enter") {
      next.current.focus();
    }
  };

  useEffect(() => {
    itemNameRef.current.focus(); //itemName is focused when boxform is mounted
  }, [itemNameRef]);

  useEffect(() => {
    if (
      !itemNameHasError &&
      !L_boxError &&
      !B_boxError &&
      !H_boxError &&
      !dimension1Error &&
      !dimension2Error &&
      !dimension3Error &&
      !dimension4Error &&
      !dimension5Error &&
      !rate1Error &&
      !rate2Error &&
      !rate3Error &&
      !rate4Error &&
      !rate5Error &&
      !PlyError &&
      !Conversion_CostError &&
      !ColorsError &&
      !L_cutoutError &&
      !B_cutoutError &&
      !WastageError &&
      !LogisticsError &&
      !Packaging_CostError &&
      !ProfitError
    ) {
      // Yaha dekhle
      setFormIsValid((i) => true);
    }
  }, [
    itemNameHasError,
    L_boxError,
    B_boxError,
    H_boxError,
    dimension1Error,
    dimension2Error,
    dimension3Error,
    dimension4Error,
    dimension5Error,
    rate1Error,
    rate2Error,
    rate3Error,
    rate5Error,
    rate4Error,
    PlyError,
    Conversion_CostError,
    ColorsError,
    L_cutoutError,
    B_cutoutError,
    WastageError,
    LogisticsError,
    Packaging_CostError,
    ProfitError,
  ]);

  //console.log(boxData);

  return (
    <>
      <UnitDropdown unit={unit} setUnit={setUnit} />
      <Stack
        spacing={2}
        sx={{
          alignItems: "center",
          backgroundColor: "whitesmoke",
        }}
      >
        <form>
          {/*Item Name*/}
          <Grid container sx={{ padding: "30px", paddingBottom: "0px" }}>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Name"
                value={itemName}
                onChange={itemNameChange}
                onBlur={itemNameBlur}
                error={itemNameHasError}
                required
                helperText={itemNameHelper}
                inputRef={itemNameRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, L_boxRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                paddingRight: "6px",
              }}
            >
              <TextField
                label="L_box"
                value={L_box}
                onChange={L_boxChange}
                onBlur={L_boxBlur}
                error={L_boxError}
                required
                helperText={L_boxHelper}
                inputRef={L_boxRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, B_boxRef),
                }}
                size="small"
              />
              <Typography sx={{ marginLeft: "5px", marginRight: "5px" }}>
                {" "}
                x{" "}
              </Typography>
              <TextField
                label="B_box"
                value={B_box}
                onChange={B_boxChange}
                onBlur={B_boxBlur}
                error={B_boxError}
                required
                helperText={B_boxHelper}
                inputRef={B_boxRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, H_boxRef),
                }}
                size="small"
              />
              <Typography sx={{ marginLeft: "5px", marginRight: "5px" }}>
                {" "}
                x{" "}
              </Typography>
              <TextField
                label="H_box"
                value={H_box}
                onChange={H_boxChange}
                onBlur={H_boxBlur}
                error={H_boxError}
                required
                helperText={H_boxHelper}
                inputRef={H_boxRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, dimension1Ref),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                paddingRight: "10px",
              }}
            >
              <TextField
                label="GSM-1D"
                value={dimension1}
                onChange={dimension1Change}
                onBlur={dimension1Blur}
                error={dimension1Error}
                required
                helperText={dimension1Helper}
                inputRef={dimension1Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, rate1Ref),
                }}
                size="small"
              />
              <TextField
                label="GSM Layer 1 Rate"
                value={rate1}
                onChange={rate1Change}
                onBlur={rate1Blur}
                error={rate1Error}
                required
                helperText={rate1Helper}
                inputRef={rate1Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, dimension2Ref),
                }}
                size="small"
                disabled={dimension1 === intialForm.formGSM1D}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                paddingRight: "6px",
              }}
            >
              <TextField
                label="GSM Layer 2 Dimension"
                value={dimension2}
                onChange={dimension2Change}
                onBlur={dimension2Blur}
                error={dimension2Error}
                required
                helperText={dimension2Helper}
                inputRef={dimension2Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, rate2Ref),
                }}
                size="small"
                disabled={dimension1 === intialForm.formGSM1D}
              />
              <TextField
                label="GSM Layer 2 Rate"
                value={rate2}
                onChange={rate2Change}
                onBlur={rate2Blur}
                error={rate2Error}
                required
                helperText={rate2Helper}
                inputRef={rate2Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, dimension3Ref),
                }}
                size="small"
                disabled={dimension2 === intialForm.formGSM2D}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                paddingRight: "6px",
              }}
            >
              <TextField
                label="GSM Layer 3 Dimension"
                value={dimension3}
                onChange={dimension3Change}
                onBlur={dimension3Blur}
                error={dimension3Error}
                required
                helperText={dimension3Helper}
                inputRef={dimension3Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, rate3Ref),
                }}
                size="small"
                disabled={dimension2 === intialForm.formGSM2D}
              />
              <TextField
                label="GSM Layer 3 Rate"
                value={rate3}
                onChange={rate3Change}
                onBlur={rate3Blur}
                error={rate3Error}
                required
                helperText={rate3Helper}
                inputRef={rate3Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, dimension4Ref),
                }}
                size="small"
                disabled={dimension3 === intialForm.formGSM3D}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                paddingRight: "6px",
              }}
            >
              <TextField
                label="GSM Layer 4 Dimension"
                value={dimension4}
                onChange={dimension4Change}
                onBlur={dimension4Blur}
                error={dimension4Error}
                required
                helperText={dimension4Helper}
                inputRef={dimension4Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, rate4Ref),
                }}
                size="small"
                disabled={dimension3 === intialForm.formGSM3D}
              />
              <TextField
                label="GSM Layer 4 Rate"
                value={rate4}
                onChange={rate4Change}
                onBlur={rate4Blur}
                error={rate4Error}
                required
                helperText={rate4Helper}
                inputRef={rate4Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, dimension5Ref),
                }}
                size="small"
                disabled={dimension4 === intialForm.formGSM4D}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                paddingRight: "6px",
              }}
            >
              <TextField
                label="GSM Layer 5 Dimension"
                value={dimension5}
                onChange={dimension5Change}
                onBlur={dimension5Blur}
                error={dimension5Error}
                required
                helperText={dimension5Helper}
                inputRef={dimension5Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, rate5Ref),
                }}
                size="small"
                disabled={dimension4 === intialForm.formGSM4D}
              />
              <TextField
                label="GSM Layer 5 Rate"
                value={rate5}
                onChange={rate5Change}
                onBlur={rate5Blur}
                error={rate5Error}
                required
                helperText={rate5Helper}
                inputRef={rate5Ref}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, L_cutoutRef),
                }}
                size="small"
                disabled={dimension5 === intialForm.formGSM5D}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2px",
                paddingRight: "6px",
              }}
            >
              <TextField
                label="L_cutout"
                value={L_cutout}
                onChange={L_cutoutChange}
                onBlur={L_cutoutBlur}
                error={L_cutoutError}
                required
                helperText={L_cutoutHelper}
                inputRef={L_cutoutRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, B_cutoutRef),
                }}
                size="small"
              />
              <Typography sx={{ marginLeft: "5px", marginRight: "5px" }}>
                {" "}
                x{" "}
              </Typography>

              <TextField
                label="B_cutout"
                value={B_cutout}
                onChange={B_cutoutChange}
                onBlur={B_cutoutBlur}
                error={B_cutoutError}
                required
                helperText={B_cutoutHelper}
                inputRef={B_cutoutRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, PlyRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="No. of Ply"
                value={Ply}
                onChange={PlyChange}
                onBlur={PlyBlur}
                error={PlyError}
                required
                helperText={PlyHelper}
                inputRef={PlyRef}
                inputProps={{
                  onKeyPress: (event) =>
                    nextFieldSelect(event, Conversion_CostRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Conversion Cost"
                value={Conversion_Cost}
                onChange={Conversion_CostChange}
                onBlur={Conversion_CostBlur}
                error={Conversion_CostError}
                required
                helperText={Conversion_CostHelper}
                inputRef={Conversion_CostRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, ColorsRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="No. of Colors"
                value={Colors}
                onChange={ColorsChange}
                onBlur={ColorsBlur}
                error={ColorsError}
                required
                helperText={ColorsHelper}
                inputRef={ColorsRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, WastageRef),
                }}
                size="small"
              />
            </Grid>

            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Wastage"
                value={Wastage}
                onChange={WastageChange}
                onBlur={WastageBlur}
                error={WastageError}
                required
                helperText={WastageHelper}
                inputRef={WastageRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, LogisticsRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Logistics"
                value={Logistics}
                onChange={LogisticsChange}
                onBlur={LogisticsBlur}
                error={LogisticsError}
                required
                helperText={LogisticsHepler}
                inputRef={LogisticsRef}
                inputProps={{
                  onKeyPress: (event) =>
                    nextFieldSelect(event, Packaging_CostRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Packaging Cost"
                value={Packaging_Cost}
                onChange={Packaging_CostChange}
                onBlur={Packaging_CostBlur}
                error={Packaging_CostError}
                required
                helperText={Packaging_CostHelper}
                inputRef={Packaging_CostRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, ProfitRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Profit"
                value={Profit}
                onChange={ProfitChange}
                onBlur={ProfitBlur}
                error={ProfitError}
                required
                helperText={ProfitHelper}
                inputRef={ProfitRef}
                size="small"
                inputProps={{
                  onKeyPress: (event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      formSubmitHandler();
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} />
            {/*Button to reset the form as well as the local storage*/}
            {/*button to submit the form,then reset all the form values to default*/}
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "2px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={resetForm}
                sx={{ marginRight: "10px" }}
              >
                CLEAR
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "2px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={formSubmitHandler}
                sx={{ marginLeft: "10px" }}
              >
                SUBMIT
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", padding: "5px" }}
            >
              {postLoading && <Typography>loading</Typography>}
              {postError && <Typography>error submiting form</Typography>}
              {error && <Typography>Please fill form correctly</Typography>}
            </Grid>
          </Grid>
        </form>
        {/* fileInput for box*/}
        {/* yaha bt h */
        /* { <FileInput product="box" /> } */}
        {/*button to remove box from localStorage*/}
      </Stack>
    </>
  );
};

export default CorrugatedForm;
