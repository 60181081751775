import { useState } from "react";
import BoxForm from "../BoxForm/BoxForm";
import CorrugatedForm from "../CorrugatedForm/CorrugatedForm";
import CourierForm from "../CourierForm/CourierForm";
import useData from "../../Hooks/useData";
import { Grid, Stack, AppBar, TextField } from "@mui/material";
import UploadCsv from "../../Components/UploadCSV/UploadCsv";
import ResetLocal from "../../Components/ResetLocalStorage/ResetLocal";
import DownloadCSV from "../../Components/DownloadCSV/DownloadCSV";
import Navbar from "../NavBar/Navbar";
import ShowData from "../ShowData/ShowData";
import Quotation from "../../Components/GenerateQuotation/Quotation";

const FormRoot = (props) => {
  const [currForm, setCurrForm] = useState("box");
  const [receiver, setReceiver] = useState("");
  //#E0F9F9;
  //#0000;
  //#FFFF;

  const {
    val: boxList,
    update: updateBox,
    emptyCurrData: emptyBoxData,
    updateFromCsv: boxFromCsv,
  } = useData("box");

  const {
    val: corrugatedList,
    update: updateCorruagted,
    emptyCurrData: emptyCorrugatedData,
    updateFromCsv: corrugatedFromCsv,
  } = useData("corrugated");

  const {
    val: courierList,
    update: updateCourier,
    emptyCurrData: emptyCourierData,
    updateFromCsv: courierFromCsv,
  } = useData("courier");

  const emptyCurrData = () => {
    if (currForm === "box") emptyBoxData();
    if (currForm === "corrugated") emptyCorrugatedData();
    if (currForm === "courier") emptyCourierData();
  };

  return (
    <Grid container>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "row",
          height: "40px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: "20px", marginLeft: "30px", color: "#00000" }}>
          HI!! {props.user.name}
        </span>
        <span
          onClick={props.signOut}
          style={{ fontSize: "20px", marginRight: "30px", color: "#00000" }}
        >
          SIGN OUT
        </span>
      </AppBar>

      <Grid item xs={12} md={2}>
        <Navbar setCurr={setCurrForm} curr={currForm} />
      </Grid>

      <Grid item md={10} xs={12}>
        {currForm === "box" && (
          <BoxForm
            updateData={updateBox}
            emptyCurrData={emptyBoxData}
            current={currForm}
            user={props.user._id}
          />
        )}
        {currForm === "corrugated" && (
          <CorrugatedForm
            updateData={updateCorruagted}
            emptyCurrData={emptyCorrugatedData}
            current={currForm}
            user={props.user._id}
          />
        )}
        {currForm === "courier" && (
          <CourierForm
            updateData={updateCourier}
            emptyCurrData={emptyCourierData}
            current={currForm}
            user={props.user._id}
          />
        )}

        {currForm === "box" && <ShowData curr={currForm} data={boxList} />}
        {currForm === "corrugated" && (
          <ShowData curr={currForm} data={corrugatedList} />
        )}
        {currForm === "courier" && (
          <ShowData data={courierList} curr={currForm} />
        )}
        <Stack direction="row" spacing={2} marginBottom="20px">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <Grid item md={2} xs={4}>
              <UploadCsv
                curr={currForm}
                addBoxData={boxFromCsv}
                addCourierData={courierFromCsv}
                addCorrugatedData={corrugatedFromCsv}
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <ResetLocal
                current={currForm}
                clearData={() => {
                  emptyCurrData();
                }}
              />
            </Grid>

            <Grid item md={2} xs={4}>
              {currForm === "box" && (
                <DownloadCSV curr={currForm} data={boxList} />
              )}
              {currForm === "corrugated" && (
                <DownloadCSV curr={currForm} data={corrugatedList} />
              )}
              {currForm === "courier" && (
                <DownloadCSV curr={currForm} data={courierList} />
              )}
            </Grid>
            <TextField
              label="Receiver"
              value={receiver}
              onChange={(e) => setReceiver(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ marginLeft: "30px", width: "200px" }}
            />
            {currForm === "box" && (
              <Quotation curr={currForm} data={{ data: boxList, receiver }} />
            )}
            {currForm === "corrugated" && (
              <Quotation
                curr={currForm}
                data={{ data: corrugatedList, receiver }}
              />
            )}
            {currForm === "courier" && (
              <Quotation
                curr={currForm}
                data={{ data: courierList, receiver }}
              />
            )}

            {/* <Quotation /> */}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FormRoot;
