import React from "react";
import { Button } from "@mui/material";
const ResetLocal = (props) => {
  const clickHandler = () => {
    localStorage.removeItem(props.current);
    props.clearData();
  };
  return <Button onClick={clickHandler} sx={{width:'120px',margin:'2px',color:"white"}} variant="contained" color="neutral">RESET</Button>;
};

export default ResetLocal;
