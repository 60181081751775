import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

const serialHead = "S.No";

const headings = {
  box: [
    serialHead,
    "Item_Name",
    "Units",
    "GSM",
    "Side_Pasting",
    "Colors",
    "Special_Colors",
    "Sheet_Price",
    "L_box",
    "B_box",
    "H_box",
    "L_cutout",
    "B_cutout",
    "Piece_Per_Sheet",
    "Basic_Amount",
    "Cost_Per_Piece",
    "Sheet_Type",
    "Total_Cost",
    "unit",
  ],
  corrugated: [
    serialHead,
    "L_box",
    "name",
    "B_box",
    "H_box",
    "dimension1",
    "dimension2",
    "dimension3",
    "dimension4",
    "dimension5",
    "rate1",
    "rate2",
    "rate3",
    "rate4",
    "rate5",
    "GSM_Layer1",
    "GSM_Layer2",
    "GSM_Layer3",
    "GSM_Layer4",
    "GSM_Layer5",
    "Ply",
    "Conversion_Cost",
    "Colors",
    "L_cutout",
    "B_cutout",
    "Wastage",
    "Logistics",
    "Packaging_Cost",
    "Profit",
    "Total_Cost",
    "unit",
  ],
  courier: [
    serialHead,
    "name",
    "L_Mailer",
    "B_Mailer",
    "micron",
    "gauge",
    "polymer_cost",
    "stack_weight",
    "bag_weight",
    "bag_rate",
    "rate_per_piece",
    "unit",
  ],
};

const stickyHeaders = {
  box: ["Total_Cost"],
  corrugated: ["Total_Cost"],
  courier: ["Total_Cost"],
};

const ShowData = (props) => {
  const data = props.data;
  console.log(data);
  const curr = props.curr;
  //console.log(headings[curr]);
  //console.log(data[0].Basic_Amount + typeof data[0].Basic_Amount);

  return (
    <Paper
      sx={{ padding: "2px", backgroundColor: "#e0f9f9", marginTop: "10px" }}
      elevation={2}
    >
      <TableContainer
        size="small"
        sx={{ height: "250px", border: "1px solid rgba(0,0,0,0.2)" }}
        component={Paper}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {headings[curr].map((head, i) =>
                stickyHeaders[curr].find((n) => n === head) ? (
                  <TableCell
                    key={i}
                    sx={{
                      position: "sticky",
                      left: 0,
                      background: "lightgrey",
                      zIndex: 801,
                      align: "center",
                    }}
                  >
                    {head}
                  </TableCell>
                ) : null
              )}
              {headings[curr].map((head, i) =>
                stickyHeaders[curr].find((n) => n !== head) ? (
                  <TableCell key={i} sx={{ backgroundColor: "lightgrey" }}>
                    {head}
                  </TableCell>
                ) : null
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, id) => (
              <TableRow key={id}>
                {headings[curr].map((val, i) =>
                  stickyHeaders[curr].find((n) => n === val) ? (
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 800,
                      }}
                      key={i}
                    >
                      {row[val] || 0}
                    </TableCell>
                  ) : null
                )}
                {headings[curr].map((val, i) =>
                  stickyHeaders[curr].find((n) => n !== val) ? (
                    <TableCell key={i}>
                      {val === serialHead ? id + 1 : row[val]}
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ShowData;

/*
<div className={classes.data}>
      <div className={classes.headings}>
        {Object.entries(data[0]).map(([key, val],index) => (
          <RowData itemData={key} key={index} />
        ))}
      </div>
      {data.map((row) => (
        <div className={classes.rowData}>
          {Object.entries(row).map(([key, val],index) => (
            <RowData itemData={val} key={index} />
          ))}
        </div>
      ))}
    </div>*/
