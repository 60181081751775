import { Button, Stack, Paper } from "@mui/material";

const Navbar = (props) => {
  const handler = (action) => {
    props.setCurr(action);
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      mr={0}
      sx={{ display: "flex", justifyContent: "center",padding:"20px" }}
    >
      <Button
        color={props.curr === "box" ? "secondary" : "primary"}
        onClick={(e) => {
          handler((i) => "box"); //handler is setCurr in App.js
        }}
        variant="contained"
      >
        BOX
      </Button>
      <Button
        color={props.curr === "corrugated" ? "secondary" : "primary"}
        onClick={(e) => {
          handler((i) => "corrugated");
        }}
        variant="contained"
      >
        corrugated
      </Button>
      <Button
        color={props.curr === "courier" ? "secondary" : "primary"}
        onClick={(e) => {
          handler((i) => "courier");
        }}
        variant="contained"
      >
        Courier Bag
      </Button>
    </Stack>
  );
};

export default Navbar;
