import { useState, useEffect } from "react";
// import Button from "../../Components/Button/Button"P
import useInput from "../../Hooks/useInput";
// import classes from "./CourierForm.module.css";
import usePost from "../../Hooks/usePost";
import { Stack, TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import UnitDropdown from "../../Components/Dropdown";

const intialForm = {
  formName: "",
  formL_Box: 0,
  formB_Box: 0,
  formMicron: 0,
  formGauge: 0,
  formPolymer_Cost: 0,
};
// helper function to get the unit multiplier
// const getUnitMultiplier = (unit) => {
//   switch (unit) {
//     case "cm":
//       return 10;
//     case "inch":
//       return 25.4;
//     case "mm":
//     default:
//       return 1;
//   }
// };

const BoxForm = (props) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState(false);
  const [unit, setUnit] = useState("inch");
  // const handleUnitChange = (event) => {
  //   setUnit(event.target.value);
  // };
  const {
    isLoading: postLoading,
    error: postError,
    sendRequest: postBoxForm,
  } = usePost(`https://rrcosting.click/courier/calculate`, { unit });

  //console.log(process.env.REACT_APP_BACKEND)

  //items initialized to localstorage value of 'item'
  //item in localstorage will be null if key is not present,so || [] is used

  //same if l_box and b_box are given then no need of L_cutout & b_cutout.
  // const unitMultiplier = getUnitMultiplier(unit);
  // console.log("Unit:", unit, "Multiplier:", unitMultiplier);
  const {
    val: itemName,
    valChange: itemNameChange,
    valBlur: itemNameBlur,
    valReset: itemNameReset,
    valError: itemNameHasError,
    helperText: itemNameHelper,
    inputRef: itemNameRef,
  } = useInput(
    intialForm.formName, //intialValue
    (i) => (i.length > 0 ? true : false), //validation
    " ", //untouched
    "Name cannot be empty", //touched and wrong
    " "
  );
  const {
    val: L_box,
    valChange: L_boxChange,
    valBlur: L_boxBlur,
    valReset: L_boxReset,
    helperText: L_boxHelper,
    valError: L_boxError,
    inputRef: L_boxRef,
  } = useInput(
    intialForm.formL_Box, //intialValue
    (i) => (i.length > 0 ? true : false), //validation
    `(${unit})`,
    "Must be greater than or equal to 0",
    " "
  );
  // console.log("Length :", L_box);

  const {
    val: B_box,
    valChange: B_boxChange,
    valBlur: B_boxBlur,
    valReset: B_boxReset,
    helperText: B_boxHelper,
    valError: B_boxError,
    inputRef: B_boxRef,
  } = useInput(
    intialForm.formB_Box, //intialValue
    (i) => (i > 0 ? true : false), //validation
    `(${unit})`,
    "Must be greater than or equal to 0",
    " "
  );

  const {
    val: Micron,
    valChange: MicronChange,
    valBlur: MicronBlur,
    valReset: MicronReset,
    helperText: MicronHelper,
    valError: MicronError,
    inputRef: MicronRef,
  } = useInput(
    intialForm.formMicron, //intialValue
    (i) => (i >= 0 ? true : true), //validation
    ``,
    "Must be greater than or equal to 0",
    " "
  );

  const {
    val: Polymer_Cost,
    valChange: Polymer_CostChange,
    valBlur: Polymer_CostBlur,
    valReset: Polymer_CostReset,
    helperText: Polymer_CostHelper,
    valError: Polymer_CostError,
    inputRef: Polymer_CostRef,
  } = useInput(
    intialForm.formPolymer_Cost, //intialValue
    (i) => (i >= 0 ? true : false), //validation
    " ",
    "Must be in Rupees",
    " "
  );

  const checkFormValid = () => {
    //setFormIsValid(true);
    if (!L_boxError && !B_boxError && !MicronError && !Polymer_CostError) {
      setFormIsValid(true);
    }
  };

  const formReset = () => {
    //reset all input values
    itemNameReset();
    L_boxReset();
    B_boxReset();
    MicronReset();
    Polymer_CostReset();
    setError((i) => false);
    setFormIsValid((i) => false);
  };
  const formSubmitHandler = (event) => {
    event.preventDefault();
    //form validation is not handled here as an invalid form has disabled submit button
    checkFormValid();
    if (formIsValid) {
      const formData = {
        name: itemName,
        L_box: L_box,
        B_box: B_box,
        Micron: Micron,
        Polymer_Cost: Polymer_Cost,
        user_id: props.user,
        unit: unit,
      };

      //console.log(formData);
      postBoxForm(formData).then((res) => {
        //console.log(res.data.result);
        props.updateData(res.data.result);
        formReset();
      });
    } else {
      setError((i) => true);
    }
  };
  console.log(itemName);

  useEffect(() => {
    if (
      !itemNameHasError &&
      !L_boxError &&
      !B_boxError &&
      !MicronError &&
      !Polymer_CostError
    ) {
      setFormIsValid(true);
    }
  }, [
    itemNameHasError,
    L_boxError,
    B_boxError,
    MicronError,
    Polymer_CostError,
  ]);

  const nextFieldSelect = (event, next) => {
    const { key } = event;
    if (key === "Enter") {
      next.current.focus();
    }
  };
  useEffect(() => {
    itemNameRef.current.focus(); //itemName is focused when boxform is mounted
  }, [itemNameRef]);

  // useEffect(() => {
  //   L_boxRef.current.focus(); //itemName is focused when boxform is mounted
  // }, [L_boxRef]);

  //console.log(boxData);

  return (
    <>
      <UnitDropdown unit={unit} setUnit={setUnit} />
      <Stack
        spacing={2}
        sx={{
          alignItems: "center",
          backgroundColor: "whitesmoke",
        }}
      >
        {/*Item Name*/}

        <Grid container sx={{ padding: "30px" }}>
          <Grid item md={2} xs={6}>
            <TextField
              label="Name"
              value={itemName}
              onChange={itemNameChange}
              onBlur={itemNameBlur}
              error={itemNameHasError}
              required
              helperText={itemNameHelper}
              inputRef={itemNameRef}
              size="small"
              inputProps={{
                onKeyPress: (event) => nextFieldSelect(event, L_boxRef),
              }}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              label="L_Mailer"
              value={L_box}
              onChange={L_boxChange}
              onBlur={L_boxBlur}
              error={L_boxError}
              helperText={L_boxHelper}
              size="small"
              inputRef={L_boxRef}
              required
              inputProps={{
                onKeyPress: (event) => nextFieldSelect(event, B_boxRef),
              }}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              label="B_Mailer"
              value={B_box}
              onChange={B_boxChange}
              onBlur={B_boxBlur}
              error={B_boxError}
              helperText={B_boxHelper}
              size="small"
              inputRef={B_boxRef}
              inputProps={{
                onKeyPress: (event) => nextFieldSelect(event, MicronRef),
              }}
              required
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              label="Micron"
              value={Micron}
              onChange={MicronChange}
              onBlur={MicronBlur}
              error={MicronError}
              helperText={MicronHelper}
              size="small"
              inputRef={MicronRef}
              required
              inputProps={{
                onKeyPress: (event) => nextFieldSelect(event, Polymer_CostRef),
              }}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextField
              label="Polymer_Cost"
              value={Polymer_Cost}
              onChange={Polymer_CostChange}
              onBlur={Polymer_CostBlur}
              error={Polymer_CostError}
              helperText={Polymer_CostHelper}
              inputRef={Polymer_CostRef}
              size="small"
              required
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    formSubmitHandler();
                  }
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end", padding: "2px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={formReset}
              sx={{ marginRight: "10px" }}
            >
              CLEAR
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "2px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={formSubmitHandler}
              disabled={postLoading}
              sx={{ marginLeft: "10px" }}
            >
              SUBMIT
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", padding: "5px" }}
          >
            {postLoading && <Typography>loading</Typography>}
            {postError && <Typography>error submiting form</Typography>}
            {error && <Typography>Please fill form correctly</Typography>}
          </Grid>
        </Grid>
        {/*Button to reset the form as well as the local storage*/}
        {/*button to submit the form,then reset all the form values to default*/}

        {/* fileInput for box*/}
        {/* yaha bt h */
        /* { <FileInput product="box" /> } */}
        {/*button to remove box from localStorage*/}
      </Stack>
    </>
  );
};

export default BoxForm;
