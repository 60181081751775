import { Button, TextField } from "@mui/material";
import { useState } from "react";

const Quotation = (props) => {
  // const [receiver, setReceiver] = useState("");
  const link = props.curr;
  // const receiver = props.receiver;

  const handleGenerateQuotation = (e) => {
    // if (receiver.trim() === "") {
    //   alert("Receiver field is empty!");
    //   return;
    // }
    fetch(`https://rrcosting.click/${link}/downloadPdf`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props.data),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Quotation.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      {/* <TextField
        label="Receiver"
        value={receiver}
        onChange={(e) => setReceiver(e.target.value)}
        variant="outlined"
        size="small"
        name="receiver"
        sx={{ marginLeft: "30px", width: "200px" }}
      /> */}

      <Button
        variant="contained"
        color="neutral"
        sx={{ width: "120px", margin: "2px", color: "white" }}
        onClick={handleGenerateQuotation}
        // disabled={!receiver.trim()}
      >
        QUOTATION
      </Button>
    </>
  );
};

export default Quotation;
