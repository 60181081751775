import { Button } from "@mui/material";

const FileUploader = (props) => {
  const link = props.curr;

  const handleFileInput = (e) => {
    fetch(`https://rrcosting.click/${link}/download`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(props.data),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `output.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  return (
    <Button
      variant="contained"
      color="neutral"
      sx={{ width: "120px", margin: "2px", color: "white" }}
      onClick={handleFileInput}
    >
      DOWNLOAD
    </Button>
  );
};

export default FileUploader;
