import { useState, useEffect } from "react";
import useInput from "../../Hooks/useInput";
import usePost from "../../Hooks/usePost";
import { Stack, TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1
import UnitDropdown from "../../Components/Dropdown";

const intialForm = {
  formName: "",
  formUnits: 0,
  formGSM: 0,
  formSidePasting: 0,
  formColors: 0,
  formSpecialColor: 0,
  formSheetPrice: 0,
  formLength: 0,
  formBreadth: 0,
  formHeight: 0,
  formLengthCutout: 0,
  formBreadthCutout: 0,
};

// helper function to get the unit multiplier
// const getUnitMultiplier = (unit) => {
//   switch (unit) {
//     case "cm":
//       return 10;
//     case "inch":
//       return 25.4;
//     case "mm":
//     default:
//       return 1;
//   }
// };

const BoxForm = (props) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState(false);
  const [unit, setUnit] = useState("mm");
  const {
    isLoading: postLoading,
    error: postError,
    sendRequest: postBoxForm,
  } = usePost(`https://rrcosting.click/box/calculate`, { unit });

  //items initialized to localstorage value of 'item'
  //item in localstorage will be null if key is not present,so || [] is used
  const {
    val: itemName,
    valChange: itemNameChange,
    valBlur: itemNameBlur,
    valReset: itemNameReset,
    valError: itemNameHasError,
    helperText: itemNameHelper,
    inputRef: itemNameRef,
  } = useInput(
    intialForm.formName, //intialValue
    (i) => (i.length > 0 ? true : false), //validation
    " ", //untouched
    "Name cannot be empty", //touched and wrong
    " "
  );

  const {
    val: units,
    valChange: unitsChange,
    valBlur: unitsBlur,
    valReset: unitsReset,
    valError: unitsHasError,
    helperText: unitsHelper,
    inputRef: unitsRef,
  } = useInput(
    intialForm.formUnits, //intialValue
    (i) => (i > 0 ? true : false), //validation
    " ",
    "Must be greater than 0",
    " "
  );

  const {
    val: GSM,
    valChange: GSMChange,
    valBlur: GSMBlur,
    valReset: GSMReset,
    valError: GSMError,
    helperText: GSMHelper,
    inputRef: GSMRef,
  } = useInput(
    intialForm.formGSM, //intialValue
    (i) => (i > 0 ? true : false), //validation
    ``,
    "Cannot be less than 0",
    " "
  );

  const {
    val: sheetPrice,
    valChange: sheetPriceChange,
    valBlur: sheetPriceBlur,
    valReset: sheetPriceReset,
    valError: sheetPriceError,
    helperText: sheetPriceHelper,
    inputRef: sheetPriceRef,
  } = useInput(
    intialForm.formSheetPrice, //intialValue
    (i) => (i >= 0 ? true : true), //validation
    " ",
    "Cannot be less than 0",
    " "
  );

  const {
    val: sidePasting,
    valChange: sidePastingChange,
    valBlur: sidePastingBlur,
    valReset: sidePastingReset,
    valError: sidePastingError,
    helperText: sidePastingHelper,
    inputRef: sidePastingRef,
  } = useInput(
    intialForm.formSidePasting, //intialValue
    (i) => (i >= 0 && i <= 6 ? true : false), //validation
    " ",
    "Must be int between 0 & 6 inclusive",
    " "
  );

  const {
    val: colors,
    valChange: colorsChange,
    valBlur: colorsBlur,
    valReset: colorsReset,
    valError: colorsError,
    helperText: colorsHelper,
    inputRef: colorsRef,
  } = useInput(
    intialForm.formColors, //intialValue
    (i) => (i >= 0 && i <= 6 ? true : false), //validation
    " ",
    "Must be int between 0 & 6 inclusive",
    " "
  );

  const {
    val: SpecialColor,
    valChange: SpecialColorChange,
    valBlur: SpecialColorBlur,
    valReset: SpecialColorReset,
    valError: SpecialColorError,
    helperText: SpecialColorHelper,
    inputRef: SpecialColorRef,
  } = useInput(
    intialForm.formSpecialColor, //intialValue
    (i) => (i >= 0 && i <= 6 ? true : false), //validation
    " ",
    "Must be int between 0 & 6 inclusive",
    " "
  );
  //either use lbh or use Lcutout B cutout

  const {
    val: length,
    valChange: lengthChange,
    valBlur: lengthBlur,
    valReset: lengthReset,
    valError: lengthError,
    helperText: lengthHelper,
    inputRef: lengthRef,
  } = useInput(
    intialForm.formLength, //intialValue
    (i) => (i >= 0 ? true : false), //validation
    `(${unit})`,
    "Either l/b/h or l-cut/b-cut",
    " "
  );

  const {
    val: breadth,
    valChange: breadthChange,
    valBlur: breadthBlur,
    valReset: breadthreset,
    valError: breadthError,
    helperText: breadthHelper,
    inputRef: breadthRef,
  } = useInput(
    intialForm.formBreadth, //intialValue
    (i) => (i >= 0 ? true : false), //validation
    `(${unit})`,
    "Either l/b/h or l-cut/b-cut",
    " "
  );

  const {
    val: height,
    valChange: heightChange,
    valBlur: heightBlur,
    valReset: heightReset,
    valError: heightError,
    helperText: heightHelper,
    inputRef: heightRef,
  } = useInput(
    intialForm.formHeight, //intialValue
    (i) => (i >= 0 ? true : false), //validation
    `(${unit})`,
    "Either l/b/h or l-cut/b-cut",
    " "
  );

  const {
    val: lengthCutout,
    valChange: lengthCutoutChange,
    valBlur: lengthCutoutBlur,
    valReset: lengthCutoutReset,
    valError: lengthCutoutError,
    helperText: lengthCutoutHelper,
    inputRef: lengthCutoutRef,
  } = useInput(
    intialForm.formLengthCutout, //intialValue
    (i) => (i >= 0 ? true : false), //validation
    `(${unit})`,
    "Either l/b/h or l-cut/b-cut",
    " "
  );

  const {
    val: breadthCutout,
    valChange: breadthCutoutChange,
    valBlur: breadthCutoutBlur,
    valReset: breadthCutoutReset,
    valError: breadthCutoutError,
    helperText: breadthCutoutHelper,
    inputRef: breadthCutoutRef,
  } = useInput(
    intialForm.formBreadthCutout, //intialValue
    (i) => (i >= 0 ? true : false), //validation
    `(${unit})`,
    "Either l/b/h or l-cut/b-cut",
    " "
  );

  const resetForm = () => {
    //reset all input values
    itemNameReset();
    unitsReset();
    GSMReset();
    colorsReset();
    sidePastingReset();
    SpecialColorReset();
    sheetPriceReset();
    lengthReset();
    breadthreset();
    heightReset();
    lengthCutoutReset();
    breadthCutoutReset();
    setFormIsValid((i) => false); //set form as invalid
    setError((i) => false);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      console.log("form submit");
      const formData = {
        units: units,
        Name: itemName,
        GSM: GSM,
        side: sidePasting,
        colors: colors,
        scolors: SpecialColor,
        price: sheetPrice,
        length: length,
        breadth: breadth,
        height: height,
        length2d: lengthCutout,
        breadth2d: breadthCutout,
        user_id: props.user,
        unit: unit,
      };

      //console.log(formData);
      postBoxForm(formData)
        .then((res) => {
          console.log(res.data.result);
          props.updateData(res.data.result);
        })
        .then(resetForm());
    } else {
      setError((i) => false);
    }
  };

  const nextFieldSelect = (event, next) => {
    const { key } = event;
    if (key === "Enter") {
      next.current.focus();
    }
  };

  useEffect(() => {
    itemNameRef.current.focus(); //itemName is focused when boxform is mounted
  }, [itemNameRef]);

  useEffect(() => {
    if (
      !itemNameHasError &&
      !unitsHasError &&
      !GSMError &&
      !sidePastingError &&
      !colorsError &&
      !SpecialColorError &&
      !sheetPriceError &&
      !lengthError &&
      !breadthError &&
      !heightError &&
      !lengthCutoutError &&
      !breadthCutoutError
    ) {
      setFormIsValid((i) => true);
    }
  }, [
    itemNameHasError,
    unitsHasError,
    sheetPriceError,
    colorsError,
    sidePastingError,
    GSMError,
    SpecialColorError,
    lengthError,
    breadthError,
    heightError,
    lengthCutoutError,
    breadthCutoutError,
  ]);

  //checks formIsValid when every field is changed
  //console.log(boxData);

  return (
    <>
      <UnitDropdown unit={unit} setUnit={setUnit} />
      <Stack
        spacing={2}
        sx={{
          backgroundColor: "whitesmoke",
          paddingTop: "5px",
        }}
      >
        <form>
          <Grid container sx={{ padding: "10px" }}>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Name"
                value={itemName}
                onChange={itemNameChange}
                onBlur={itemNameBlur}
                error={itemNameHasError}
                required
                helperText={itemNameHelper}
                inputRef={itemNameRef}
                size="small"
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, unitsRef),
                }}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Units"
                type="number"
                value={units}
                onChange={unitsChange}
                onBlur={unitsBlur}
                required
                error={unitsHasError}
                helperText={unitsHelper}
                inputRef={unitsRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, GSMRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="GSM"
                type="number"
                value={GSM}
                onChange={GSMChange}
                onBlur={GSMBlur}
                error={GSMError}
                required
                helperText={GSMHelper}
                inputRef={GSMRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, sidePastingRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Side Pasting"
                value={sidePasting}
                onChange={sidePastingChange}
                onBlur={sidePastingBlur}
                error={sidePastingError}
                type="number"
                required
                helperText={sidePastingHelper}
                inputRef={sidePastingRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, colorsRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Colors"
                value={colors}
                onChange={colorsChange}
                onBlur={colorsBlur}
                error={colorsError}
                type="number"
                required
                helperText={colorsHelper}
                inputRef={colorsRef}
                inputProps={{
                  onKeyPress: (event) =>
                    nextFieldSelect(event, SpecialColorRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Special Colors"
                value={SpecialColor}
                onChange={SpecialColorChange}
                onBlur={SpecialColorBlur}
                error={SpecialColorError}
                type="number"
                required
                helperText={SpecialColorHelper}
                inputRef={SpecialColorRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, sheetPriceRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Sheet Price"
                value={sheetPrice}
                onChange={sheetPriceChange}
                onBlur={sheetPriceBlur}
                error={sheetPriceError}
                type="number"
                required
                helperText={sheetPriceHelper}
                inputRef={sheetPriceRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, lengthRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="L"
                value={length}
                onChange={lengthChange}
                onBlur={lengthBlur}
                error={lengthError}
                type="number"
                required
                helperText={lengthHelper}
                inputRef={lengthRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, breadthRef),
                }}
                size="small"
              />
              <Typography sx={{ marginLeft: "5px", marginRight: "5px" }}>
                {" "}
                x{" "}
              </Typography>
              <TextField
                label="B"
                value={breadth}
                onChange={breadthChange}
                onBlur={breadthBlur}
                error={breadthError}
                type="number"
                required
                helperText={breadthHelper}
                inputRef={breadthRef}
                inputProps={{
                  onKeyPress: (event) => nextFieldSelect(event, heightRef),
                }}
                size="small"
              />
              <Typography sx={{ marginLeft: "5px", marginRight: "5px" }}>
                {" "}
                x{" "}
              </Typography>
              <TextField
                label="H"
                value={height}
                onChange={heightChange}
                onBlur={heightBlur}
                required
                error={heightError}
                type="number"
                helperText={heightHelper}
                inputRef={heightRef}
                inputProps={{
                  onKeyPress: (event) =>
                    nextFieldSelect(event, lengthCutoutRef),
                }}
                size="small"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "center", padding: "2px" }}
            >
              <TextField
                label="Length Cutouts"
                value={lengthCutout}
                onChange={lengthCutoutChange}
                onBlur={lengthCutoutBlur}
                error={lengthCutoutError}
                type="number"
                required
                helperText={lengthCutoutHelper}
                inputRef={lengthCutoutRef}
                inputProps={{
                  onKeyPress: (event) =>
                    nextFieldSelect(event, breadthCutoutRef),
                }}
                size="small"
              />

              <Typography sx={{ marginLeft: "5px", marginRight: "5px" }}>
                {" "}
                x{" "}
              </Typography>
              <TextField
                label="B-Cutouts"
                value={breadthCutout}
                onChange={breadthCutoutChange}
                onBlur={breadthCutoutBlur}
                error={breadthCutoutError}
                type="number"
                required
                helperText={breadthCutoutHelper}
                inputRef={breadthCutoutRef}
                size="small"
                inputProps={{
                  onKeyPress: (event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      formSubmitHandler(event);
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "2px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={resetForm}
                sx={{ marginRight: "10px" }}
              >
                CLEAR
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "2px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={formSubmitHandler}
                disabled={postLoading}
                sx={{ marginLeft: "10px" }}
              >
                SUBMIT
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", padding: "5px" }}
            >
              {postLoading && <Typography>Loading... </Typography>}
              {postError && <Typography> Error Submiting Form. </Typography>}
              {error && (
                <Typography> Please fill the Form Correctly!</Typography>
              )}
            </Grid>
          </Grid>
        </form>
        {/*Item Name*/}
        {/*Button to reset the form as well as the local storage*/}
        {/*button to submit the form,then reset all the form values to default*/}

        {/* fileInput for box*/}
        {/* yaha bt h */
        /* { <FileInput product="box" /> } */}
        {/*button to remove box from localStorage*/}
      </Stack>
    </>
  );
};

export default BoxForm;
