import { useState,useRef } from "react";
import { TextField } from "@mui/material";

const useInput = (
  initialValue,
  validate,
  initialMessage,
  wrongMessage,
  correctMessage
) => {
  const [val, setVal] = useState(initialValue); //value of the input field
  const [touched, setTouched] = useState(false); //see if the input field has already been clicked
  const inputRef = useRef(null); //the ref to handle enter key form navigation

  const valChange = (event) => {
    setVal(event.target.value);
  };

  const valBlur = (event) => {
    if (val === undefined || null) setVal("");
    setTouched(true);
  };

  const valReset = () => {
    setVal((i) => initialValue);
    setTouched((i) => false);
  };

  const valError = touched && !validate(val) ? true : false;

  let helperText = initialMessage;
  if (touched) {
    validate(val) ? (helperText = correctMessage) : (helperText = wrongMessage);
  }

  //const inputField=<TextField></TextField>;

  return {
    val,
    valChange,
    valBlur,
    valReset,
    valError,
    helperText,
    inputRef,
  };
};

export default useInput;
