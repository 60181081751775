import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Link, TextField, Typography, Paper } from "@mui/material";
import usePost from "../../Hooks/usePost";
import Grid from "@mui/material/Grid"; // Grid version 1
import useInput from "../../Hooks/useInput";

const postUrl = "https://rrcosting.click/auth/login";

const LoginPage = (props) => {
  const {
    val: email,
    valChange: emailChange,
    valBlur: emailBlur,
    valReset: emailReset,
    valError: emailError,
    helperText: emailHelper,
    inputRef: emailRef,
  } = useInput(
    "",
    (mail) => /\S+@\S+\.\S+/.test(mail),
    "",
    "not a valid mail",
    ""
  );

  const {
    val: password,
    valChange: passwordChange,
    valBlur: passwordBlur,
    valReset: passwordReset,
    valError: passwordError,
    helperText: passwordHelper,
    inputRef: passwordRef,
  } = useInput("", (p) => p.length >= 8, "", "atleast the size of 8", "");

  const {
    isLoading: postLoading,
    error: postError,
    sendRequest,
  } = usePost(postUrl);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (emailError || passwordError) return;
    //const errors = validate(email, password);
    const data = {
      email: email,
      password: password,
    };

    sendRequest(data)
      .then((res) => {
        console.log(res);
        return res.data.user;
      })
      .then((logUser) => props.user((i) => logUser))
      .then(() => {
        emailReset();
        passwordReset();
      });
  };

  const nextFieldSelect = (event, next) => {
    const { key } = event;
    if (key === "Enter") {
      next.current.focus();
    }
  };

  useEffect(() => {
    emailRef.current.focus(); //itemName is focused when boxform is mounted
  }, [emailRef]);

  console.log("postError:" + postError);

  return (
    <Paper
      spacing={2}
      sx={{
        backgroundColor: "#FAFAFA",
        width: "300px",
        height: "400px",
        margin: "auto",
        marginTop: "100px",
      }}
    >
      {/* <div> */}
      <Grid container justifyContent="center">
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <h2 align="center">LOGIN</h2>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              width="200px"
              required
              value={email}
              onChange={emailChange}
              onBlur={emailBlur}
              error={emailError}
              helperText={emailHelper}
              inputRef={emailRef}
              inputProps={{
                onKeyPress: (event) => nextFieldSelect(event, passwordRef),
              }}
              marginBottom="10px"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Password"
              variant="outlined"
              width="200px"
              required
              margin="normal"
              type="password"
              value={password}
              onChange={passwordChange}
              onBlur={passwordBlur}
              error={passwordError}
              helperText={passwordHelper}
              inputRef={passwordRef}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    handleSubmit(event);
                  }
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ marginLeft: "60px", color: "black" }}
            >
              <Link
                to="/forgotPassword"
                component={RouterLink}
                sx={{ color: "black" }}
              >
                Forgot Password?
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginLeft: "73px", marginTop: "10px" }}
            >
              Login
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="center"
              marginTop="20px"
              gutterBottom
              color="neutral"
            >
              Haven't yet signed up?{" "}
              <Link to="/signup" component={RouterLink} sx={{ color: "red" }}>
                Sign Up Now
              </Link>
            </Typography>
          </Grid>

          {/* </div> */}
        </form>
        <Grid item xs={12}>
          {postError && (
            <Typography
              variant="body2"
              align="center"
              marginTop="20px"
              gutterBottom
              sx={{ color: "red" }}
            >
              invalid username or password
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {postLoading && (
            <Typography
              variant="body2"
              align="center"
              marginTop="20px"
              gutterBottom
            >
              Signing......
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoginPage;
