//import Test from "./Components/Test/Test";
import { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import LoginPage from "./Pages/Login/LoginPage";
import SignupPage from "./Pages/Signup/SignupPage";
import FormRoot from "./Pages/FormRoot/FormRoot";
import ForgotPasswordPage from "./Pages/ForgetPassword/ForgotPasswordPage";
import { Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider, colors } from "@mui/material";

const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null); //only the username is saved

  const signOutHandler = () => {
    localStorage.removeItem("user");
    setUser(i=>null);
  };

  useEffect(() => {
    //on each change of items pull all items from localstorage and update changes
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary:{
        main:"#006600"
      },
      secondary: {
        main: "#EEEE",
      },
      error: {
        main: colors.red[500],
      },
      neutral:{
        main:"#000000",
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack
        sx={{
          backgroundColor: "#eeee",
          alignItems: "center",
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/form" />} />
          <Route
            path="login"
            element={
              user ? <Navigate to="/" replace /> : <LoginPage user={setUser} />
            }
          />
          <Route
            path="signup"
            element={
              user ? <Navigate to="/" replace /> : <SignupPage user={setUser} />
            }
          />
          <Route
            path="form"
            element={
              !user ? (
                <Navigate to="/login" replace />
              ) : (
                <FormRoot signOut={signOutHandler} user={user} />
              )
            }
          />
          <Route
            path="forgotPassword"
            element={
              user ? (
                <Navigate to="/" replace />
              ) : (
                <ForgotPasswordPage user={setUser} />
              )
            }
          />
        </Routes>
      </Stack>
    </ThemeProvider>
  );

  /*
  return (
    <>
      <Paper
        color="transparent"
        sx={{
          padding: "10px",
          marginTop: "20px",
          backgroundColor: "#eeee",
          alignItems: "center",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={2}>
            <Navbar setCurr={setCurrForm} curr={currForm} />
          </Grid>
          <Grid item md={10} xs={12}>
            {currForm === "box" && (
              <BoxForm
                updateData={updateBox}
                emptyCurrData={emptyBoxData}
                current={currForm}
              />
            )}
            {currForm === "corrugated" && (
              <CorrugatedForm
                updateData={updateCorruagted}
                emptyCurrData={emptyCorrugatedData}
                current={currForm}
              />
            )}
            {currForm === "courier" && (
              <CourierForm
                updateData={updateCourier}
                emptyCurrData={emptyCourierData}
                current={currForm}
              />
            )}
            <Stack
              direction="row"
              spacing={2}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <Grid item md={2} xs={4}>
                  <UploadCsv
                    curr={currForm}
                    addBoxData={boxFromCsv}
                    addCourierData={courierFromCsv}
                    addCorrugatedData={corrugatedFromCsv}
                  />
                </Grid>
                <Grid item md={2} xs={4}>
                  <ResetLocal
                    current={currForm}
                    clearData={() => {
                      emptyCurrData();
                    }}
                  />
                </Grid>
                <Grid item md={2} xs={4}>
                  {currForm === "box" && (
                    <DownloadCSV curr={currForm} data={boxList} />
                  )}
                  {currForm === "corrugated" && (
                    <DownloadCSV curr={currForm} data={corrugatedList} />
                  )}
                  {currForm === "courier" && (
                    <DownloadCSV curr={currForm} data={courierList} />
                  )}
                </Grid>
              </Grid>
            </Stack>
            {currForm === "box" && <ShowData curr={currForm} data={boxList} />}
            {currForm === "corrugated" && (
              <ShowData curr={currForm} data={corrugatedList} />
            )}
            {currForm === "courier" && (
              <ShowData data={courierList} curr={currForm} />
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
  */
};

export default App;
