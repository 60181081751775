import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const styles = {
  select: {
    width: 120,
  },
  container: {
    textAlign: "left",
  },
};

const UnitDropdown = ({ unit, setUnit }) => {
  // const [unit, setUnit] = useState("mm");

  const handleChange = (event) => {
    setUnit(event.target.value);
  };

  return (
    <FormControl style={styles.container}>
      <InputLabel>Unit</InputLabel>
      <Select value={unit} onChange={handleChange} style={styles.select}>
        <MenuItem value={"mm"}>mm</MenuItem>
        <MenuItem value={"cm"}>cm</MenuItem>
        <MenuItem value={"inch"}>inch</MenuItem>
      </Select>
    </FormControl>
  );
};

export default UnitDropdown;
