import { Stack } from "@mui/system";
import { Button } from "@mui/material";

function CustomFileInput(props) {
  const link = props.curr + "Calculate";

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const [file] = e.target.files;

    const formData = new FormData();
    formData.append("files", file);
    //console.log(formData);
    fetch(`https://rrcosting.click/${link}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result.data.records);
        if (props.curr === "box") props.addBoxData(result.data.records);
        if (props.curr === "corrugated")
          props.addCorrugatedData(result.data.records);
        if (props.curr === "courier") props.addCourierData(result.data.records);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Stack>
      {/* Our custom button to select and upload a file */}
      {/* Notice the `display: hidden` on the input */}
      <input
        type="file"
        encType="multipart/form-data"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="filePicker"
      />
      <Button
        variant="contained"
        sx={{ width: "120px", margin: "2px", color: "white" }}
        color="neutral"
      >
        <label htmlFor="filePicker" style={{ width: "120px" }}>
          IMPORT
        </label>
      </Button>
    </Stack>
  );
}

export default CustomFileInput;
