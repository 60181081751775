import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Link,
  TextField,
  Typography,
  Stack,
  Grid,
} from "@mui/material";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Simulate sending a password reset email
    if (email === "example@example.com") {
      setSuccessMessage(
        "If an account with this email exists, you will receive an email with instructions on how to reset your password shortly."
      );
      setErrorMessage(null);
    } else {
      setErrorMessage("Sorry, we could not find an account with that email.");
      setSuccessMessage(null);
    }
  };

  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: "#FAFAFA",
        width: "300px",
        height: "400px",
        margin: "auto",
      }}
    >
      {successMessage && (
        <Typography variant="body1" align="center" gutterBottom>
          {successMessage}
        </Typography>
      )}
      {errorMessage && (
        <Typography variant="body1" align="center" gutterBottom color="error">
          {errorMessage}
        </Typography>
      )}
      {!successMessage && (
        <Grid container justifyContent="center">
          <form onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <h2 align="center">Forgot your Password?</h2>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="New Password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </form>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2" align="center" gutterBottom>
          Haven't yet signed up?{" "}
          <Link to="/signup" component={RouterLink}>
            Sign Up
          </Link>
        </Typography>
      </Grid>
    </Stack>
  );
};

export default ForgotPasswordPage;
