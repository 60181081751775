import { useState,useEffect } from "react";

const useData = (id) => {
  const [val, setVal] = useState(JSON.parse(localStorage.getItem(id)) || []);

  const update = (newItem) => {
    setVal(val=>[newItem, ...val]); //will call above useEffect and update local storage
  };

  const emptyCurrData = () => {
    setVal([]);
  };

  useEffect(() => {
    //on each change of items pull all items from localstorage and update changes
    localStorage.setItem(id, JSON.stringify(val));
  }, [val,id]);

  const updateFromCsv = (newList) => {
    const tempData = [];
    for (let i = 0; i < val.length; i++) {
      tempData.push(val[i]);
    }
    for (let i = 0; i < newList.length; i++) {
      tempData.push(newList[i]);
    }
    setVal((data) => tempData);
  };
  
  return {
    val,
    update,
    emptyCurrData,
    updateFromCsv,
  };
};

export default useData;
