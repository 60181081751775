import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import usePost from "../../Hooks/usePost";
import useInput from "../../Hooks/useInput";

const postUrl = "https://rrcosting.click/auth/signup";

const SignupPage = (props) => {
  const [userName, setuserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const {
    isLoading: postLoading,
    error: postError,
    sendRequest,
  } = usePost(postUrl);

  console.log(postError);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: userName,
      email: email,
      password: password,
      passwordConfirm: confirmPassword,
    };
    //let userName="your name"
    sendRequest(data)
      .then((res) => res.data.user)
      .then((logUser) => props.user((i) => logUser));
    // Add your signup logic here
  };

  return (
    <Paper
      spacing={2}
      sx={{
        backgroundColor: "#FAFAFA",
        width: "300px",
        height: "500px",
        margin: "auto",
        marginTop: "100px",
      }}
    >
      <Grid container justifyContent="center">
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <h2 align="center">SIGN UP</h2>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="userName"
              label="User Name"
              variant="outlined"
              fullWidth
              required
              value={userName}
              onChange={(e) => setuserName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginTop: "20px" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ marginTop: "20px" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="password"
              label="Confirm Password"
              variant="outlined"
              fullWidth
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ marginTop: "20px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginLeft: "73px", marginTop: "10px" }}
            >
              Signup
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="center"
              marginTop="20px"
              gutterBottom
            >
              <Link to="/login">Already have an account?</Link>
            </Typography>
          </Grid>
        </form>
        <Grid item xs={12}>
          {postError && (
            <Typography
              variant="body2"
              align="center"
              marginTop="20px"
              gutterBottom
            >
              server was busy
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {postLoading && (
            <Typography
              variant="body2"
              align="center"
              marginTop="20px"
              gutterBottom
            >
              Signing......
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SignupPage;
